<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond();

export default {
  props: {
    id: {
      default: "",
    },
  },
  components: {
    FilePond,
  },
  data() {
    return {
      files: null,
      success: false,

      url: process.env.VUE_APP_URL_API,
      token: localStorage.token,
    };
  },
  methods: {
    getFiles() {
      console.log("");
    },
  },
  created() {
    setOptions({
      server: {
        process: {
          headers: {
            "x-auth-token": localStorage.token,
          },
          onload: (response) => {
            console.log(response)
          }
        },
      },
    });
  },
};
</script>

<template>
  <div>
    <file-pond
      name="file"
      ref="file"
      allowRevert="false"
      v-bind:files="files"
      label-idle="Arraste aqui ou escolha um arquivo"
      :server="{
        url:
          url +
          'documents/' +
          this.$props.id,
        process: {
          headers: {
            'x-auth-token': this.token,
          },
          onload: () => {
            this.$parent.$parent.getDocuments()
          }
        },
      }"
    />
  </div>
</template>

<style scoped>
.report {
  margin-top: -90px;
}
.label {
  line-height: 13px;
}
</style>
